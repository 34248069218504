import { Button, Col, Row } from "antd";
import React from "react";

interface Props {
  location: any;
}

const DealerportalLoginPage: React.FC<Props> = (props) => {
  const internal_url = process.env.REACT_APP_INTERNAL_USER_SSO_URL;
  const dealer_url = process.env.REACT_APP_DEALER_USER_SSO_URL;

  return (
    <Row className="login" align="middle" justify="center">
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8 }} className="login-col">
        <h1>Login Access</h1>
        <div className="button-group">
          <Button
            type="primary"
            className="login-form-button"
            onClick={() => { window.location.href = internal_url; }}
          >
            CNH Internal Access
          </Button>
          <Button
            type="primary"
            className="login-form-button"
            onClick={() => { window.location.href = dealer_url; }}
          >
            Dealer Access
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default DealerportalLoginPage;
